<template>
  <div class="w-100 h-100 py-30 px-10" style="background: rgba(33, 34, 47, 0.5)" v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.5)">
    <div class="d-flex ai-center jc-between mx-40 title_back">
      <p class="hover" @click="$router.back()">
        <i class="el-icon-arrow-left m-r-5"></i>{{ $t("dragon.back") }}
      </p>
      <div class="box-111" @click="goChain">
        <img src="@/assets/public/111.png" alt="">
        <span>{{$t('111')}}</span>
      </div>
      <div class="d-flex ai-center">
        <!-- 出售 -->
        <!-- <div
          class="opration text-center cursor"
          v-if="item.assets_status == 1 && $route.query.type == 'account'"
        >
          {{ $t("account.sell") }}
        </div> -->
        <!-- 下架 -->
        <!-- <div
          class="opration text-center hasactive t-color2 notallow"
          v-else-if="item.assets_status == 5"
        >
          {{ $t("card.unsell") }}
        </div> -->
        <!-- 售卖 购买 -->
        <!-- <div
          class="opration opration2 text-center cursor"
          v-else-if="$route.query.type == 'marketplace'"
        >
          {{ $t("card.buy") }}
        </div> -->

        <!-- 拍卖 -->
        <div class="opration text-center cursor" @click.stop="toSell(item)"
          v-if="(item.assets_status == 12||item.assets_status == 15) && infos.userid == item.userid">
          {{ $t("market.status.auction") }}
        </div>
      </div>
    </div>
    <div class="main d-flex ai-center m-t-20">
      <div class="main_left d-flex flex-column ai-center">
        <img v-if="item.attrs" :src="require('@/assets/hero/' + item.attrs.heroid + '.png')" width="60%"
          style="position:relative;" />
        <img :src="require('@/assets/card/card_yin.png')" width="100%" style="margin-top:-100px" />
        <!-- For sale v-if="item.assets_status == 5" -->
        <div class="forsale d-flex flex-column ai-center jc-center" v-if="item.assets_status == 13">
          <!-- <p class="fs-md">{{ $t("account.forsale") }}</p> -->
          <!-- <p class="fs-sm m-t-30">
            {{ $t("account.salePrice") }}: {{ Number(item.order_price) }}BNB
          </p> -->
          <div class="w-100 m-t-10 fs-md d-flex ai-center jc-between">
            <!-- <span>{{ $t("market.status.minPrice") }}:</span> -->
            <span>{{ $t("market.price") }}:</span>
            <span>{{ item.bid_price | cutZero }} BNB</span>
          </div>
          <!-- 买入 -->
          <!-- <el-button
            class="w-100"
            @click.stop="buyFun(item)"
            v-if="item.assets_status == 5 && infos.userid != item.userid"
            >{{ $t("market.status.buy") }}</el-button
          > -->
          <!-- 下架 -->
          <!-- <div
            class="opration text-center hasactive2 t-color3 m-t-15 fs-md"
            v-else-if="infos.userid == item.userid"
            @click.stop="takeOff(item)"
          >
             {{ $t("account.shelf") }}
          </div> -->
          <!-- 出售 -->
          <!-- <el-button
            class="w-100"
            @click.stop="toSell(item)"
            v-else-if="item.assets_status == 1"
          >
            {{ $t("account.sell") }}
          </el-button> -->

          <!-- 其他状态 -->
          <!-- <div
            class="w-100 py-10 my-10 text-center default t-color2 notallow"
            v-else
          >
            {{ status(item.assets_status) }}
          </div> -->

          <!-- 参与出价 -->
          <el-button class="opration text-center hasactive2 t-color1 m-t-15 fs-md" @click.stop="toSell(item)"
            v-if="item.assets_status == 13 && infos.userid != item.userid">
            {{ $t("market.status.auctionadd") }}</el-button>
          <!-- 拍卖中不可操作 -->
          <div class="w-100 py-10 my-10 text-center default t-color2 notallow"
            v-else-if="item.assets_status == 13 && infos.userid == item.userid">
             {{ $t("market.status.auctioning") }}
          </div>
          <p class=" m-t-10"><span>{{$t("card.timeout")}}</span>
            <Counts :item="item" />
          </p>
        </div>

        <!-- <h1 class="t-center">{{ hero_name }}</h1> -->
      </div>
      <div class="main_right flex-1 m-l-20">
        <!-- 交易历史 -->
        <div v-if="item.assets_status == 13" class="d-flex ai-center jc-between m-t-40 m-b-15">
          <p class="bold fs-xl">{{ $t("card.history") }}</p>
          <div class="d-flex ai-center">
            <p class="t-color2 fs-md m-r-5">
              <span>{{ $t("card.token") }}:</span>
              {{ this.item.tokenid }}
            </p>
            <img src="@/assets/public/copy.png" width="22px" v-clipboard:copy="this.item.tokenid"
              v-clipboard:success="onCopy" v-clipboard:error="onError" />
          </div>
        </div>
        <div v-if="item.assets_status == 13" class="section">
          <div class="title w-100 t-color2 d-flex ai-center jc-between m-b-20">
            <p></p>
            <p class="price">{{ $t("card.price") }}</p>
            <p>{{ $t("card.to") }}</p>
            <p>{{ $t("card.time") }}</p>
          </div>
          <div v-loading="loading2" element-loading-background="rgba(0, 0, 0, 0.5)" class="history-box">
            <div class="title w-100 d-flex ai-center jc-between m-b-10" v-for="(item, index) in historyList"
              :key="index">
              <p v-if="index"></p>
              <p v-else class="now-box">
                {{$t("card.from")}}
              </p>
              <p class="price">{{ item.price | cutZero }} BNB</p>
              <p class="d-flex ai-center">
                <span class="m-r-5">{{ item.buyer | plusXing(4, 4) }}</span>
                <img src="@/assets/public/copy.png" width="22px" v-clipboard:copy="item.buyer"
                  v-clipboard:success="onCopy" v-clipboard:error="onError" />
              </p>
              <p>{{item.tx_time}}</p>
            </div>
          </div>
        </div>
        <!-- <div v-if="item.assets_status == 13" class="d-flex ai-center jc-between m-t-40 m-b-15">
          <p class="bold fs-xl">{{ $t("card.history") }}</p>
          <div class="d-flex ai-center">
            <p class="t-color2 fs-md m-r-5">
              <span>{{ $t("card.token") }}:</span>
              {{ this.item.tokenid }}
            </p>
            <img
              src="@/assets/public/copy.png"
              width="22px"
              v-clipboard:copy="this.item.tokenid"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            />
          </div>
        </div>
        <div v-if="item.assets_status == 13" class="section">
          <div class="title w-100 t-color2 d-flex ai-center jc-between m-b-20">
            <p>{{ $t("card.time") }}</p>
            <p>{{ $t("card.from") }}</p>
            <p>{{ $t("card.to") }}</p>
            <p class="price">{{ $t("card.price") }}</p>
          </div>
          <div
            v-loading="loading2"
            element-loading-background="rgba(0, 0, 0, 0.5)"
          >
            <div
              class="title w-100 d-flex ai-center jc-between m-b-10"
              v-for="(item, index) in historyList"
              :key="index"
            >
              <p>{{ item.buy_address }}</p>
              <p class="d-flex ai-center">
                {{ $t("card.rice")
                }}<span class="t-color2 m-r-5"
                  >({{ item.buy_address | plusXing(4, 4) }})</span
                >
                <img
                  src="@/assets/public/copy.png"
                  width="22px"
                  class="hover"
                  v-clipboard:copy="item.transaction_time"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                />
              </p>
              <p class="d-flex ai-center">
                {{ $t("card.rice")
                }}<span class="t-color2 m-r-5"
                  >({{ item.sell_address | plusXing(4, 4) }})</span
                >
                <img
                  src="@/assets/public/copy.png"
                  width="22px"
                  v-clipboard:copy="item.sell_address"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                />
              </p>
              <p class="price">{{ item.price | cutZero }} BNB</p>
            </div>
          </div>
        </div> -->
        <!-- 基础资讯 -->
        <p class="bold fs-xl info">{{ $t("card.info") }}</p>
        <div class="section m-t-10">
          <div class="border_b d-flex ai-center jc-between p-b-30 colbox">
            <div class="d-flex flex-column col">
              <p class="t-color2">{{ $t("card.name") }}</p>
              <p class="m-t-15">{{ hero_name }}</p>
            </div>
            <div class="d-flex flex-column col">
              <p class="t-color2">{{ $t("card.grade") }}</p>
              <!-- 级别 -->
              <p class="m-t-15" v-if="item.attrs">{{ item.attrs.grade }}</p>
            </div>
            <div class="d-flex flex-column col">
              <p class="t-color2">{{ $t("card.camp") }}</p>
              <!-- 种族 -->
              <p v-if="item.attrs" class="m-t-15">
                {{ campStatus(item.attrs.herocamp) }}
              </p>
            </div>
            <div class="d-flex flex-column col">
              <p class="t-color2">{{ $t("card.profession") }}</p>
              <!-- 职业 -->
              <p class="m-t-15" v-if="item.attrs">
                {{ prostatus(item.attrs.profession_type) }}
              </p>
            </div>
          </div>
          <div class="border_b d-flex ai-center jc-between p-t-20 p-b-30 colbox">
            <div class="d-flex flex-column col">
              <p class="t-color2">{{ $t("card.power") }}</p>
              <!-- 战力 -->
              <p v-if="item.attrs" class="m-t-15">{{ item.attrs.heropower }}</p>
            </div>
            <div class="d-flex flex-column col">
              <p class="t-color2">{{ $t("card.level") }}</p>
              <!-- 等级 -->
              <p v-if="item.attrs" class="m-t-15">{{ item.attrs.herolevel }}</p>
            </div>
            <div class="d-flex flex-column col">
              <p class="t-color2">{{ $t("card.star") }}</p>
              <!-- 星级 -->
              <p v-if="item.attrs" class="m-t-15">{{ item.attrs.herostar }}</p>
            </div>
            <div class="d-flex flex-column col">
              <p class="t-color2">{{ $t("card.num") }}</p>
              <!-- 编号 -->
              <p v-if="item.attrs" class="m-t-15">{{ item.attrs.heroid }}</p>
            </div>
          </div>
          <div class="d-flex ai-center jc-between p-t-20">
            <div class="d-flex flex-column">
              <p class="t-color2">{{ $t("card.totalamount") }}</p>
              <!-- 总交易额 -->
              <p v-if="item.attrs" class="m-t-15">{{item.total_trade_amount | cutZero}}</p>
            </div>
          </div>
        </div>
        <p class="bold fs-xl m-t-40">{{ $t("card.attributes") }}</p>
        <div class="section m-t-10">
          <div class="d-flex ai-center jc-between">
            <div class="d-flex flex-column">
              <p class="t-color2">{{ $t("card.blood") }}</p>
              <!-- 血量 -->
              <p class="m-t-15">{{ arrtibute.hp_max }}</p>
            </div>
            <div class="d-flex flex-column">
              <p class="t-color2">{{ $t("card.attack") }}</p>
              <!-- 攻击 -->
              <p class="m-t-15">{{ arrtibute.atk }}</p>
            </div>
            <div class="d-flex flex-column">
              <!-- 防御 -->
              <p class="t-color2">{{ $t("card.defense") }}</p>
              <p class="m-t-15">{{ arrtibute.def }}</p>
            </div>
            <div class="d-flex flex-column">
              <p class="t-color2">{{ $t("card.speed") }}</p>
              <!-- 速度 -->
              <p class="m-t-15">{{ arrtibute.speed }}</p>
            </div>
          </div>
        </div>
        <p class="bold fs-xl m-t-40">{{ $t("card.sattributes") }}</p>
        <div class="section d-flex jc-between m-t-10">
          <div class="left border_r p-r-40">
            <!-- 暴击率 -->
            <p>
              <span class="t-color2">{{ $t("card.rate") }}</span><span>{{ arrtibute.crit }}%</span>
            </p>
            <!-- 控制 -->
            <p>
              <span class="t-color2 my-20">{{ $t("card.control") }}</span><span>{{ arrtibute.control }}%</span>
            </p>
            <!-- 抗暴 -->
            <p>
              <span class="t-color2">{{ $t("card.riot") }}</span><span>{{ arrtibute.crit_def }}%</span>
            </p>
            <!-- 免伤 -->
            <p>
              <span class="t-color2 my-20">{{ $t("card.free") }}</span><span>{{ arrtibute.hurt_free }}%</span>
            </p>
            <!-- 治疗 -->
            <p>
              <span class="t-color2">{{ $t("card.heal") }}</span><span>{{ arrtibute.cure }}%</span>
            </p>
            <!-- 伤害加成 -->
            <p>
              <span class="t-color2 my-20">{{ $t("card.damage") }}</span><span>{{ arrtibute.hurt_deep }}%</span>
            </p>
            <!-- <p><span class="t-color2">Bonus</span><span>15%</span></p> -->
            <!-- 法伤 -->
            <p>
              <span class="t-color2">{{ $t("card.spelldamage") }}</span><span>{{ arrtibute.mofa_deep }}%</span>
            </p>
            <!-- 法免 -->
            <p>
              <span class="t-color2 my-20">{{ $t("card.tospelldamage") }}</span><span>{{ arrtibute.mofa_free }}%</span>
            </p>
          </div>
          <div class="right p-l-40">
            <!-- 暴击伤害 -->
            <p>
              <span class="t-color2">{{ $t("card.crit") }}</span><span>{{ arrtibute.crit_ratio }}%</span>
            </p>
            <!-- 抗控 -->
            <p>
              <span class="t-color2 my-20">{{ $t("card.harm") }}</span><span>{{ arrtibute.control_def }}%</span>
            </p>
            <!-- 命中 -->
            <p>
              <span class="t-color2">{{ $t("card.hit") }}</span><span>{{ arrtibute.hit }}%</span>
            </p>
            <!-- 闪避 -->
            <p>
              <span class="t-color2 my-20">{{ $t("card.dodge") }}</span><span>{{ arrtibute.dodge }}%</span>
            </p>
            <!-- 被治疗 -->
            <p>
              <span class="t-color2">{{ $t("card.treatment") }}</span><span>{{ arrtibute.be_cure }}%</span>
            </p>
            <!-- 物伤 -->
            <p>
              <span class="t-color2 my-20">{{ $t("card.pdamage") }}</span><span>{{ arrtibute.wuli_deep }}%</span>
            </p>
            <!-- 物免 -->
            <p class="m-b-20">
              <span class="t-color2">{{ $t("card.damagefree") }}</span><span>{{ arrtibute.wuli_free }}%</span>
            </p>
          </div>
        </div>
        <!-- 英雄技能 -->
        <p class="bold fs-xl m-t-40">{{ $t("card.heroSkill") }}</p>
        <div class="section d-flex flex-column m-t-10">
          <!-- 技能1 -->
          <div class="d-flex ai-center my-15" v-if="skillname1">
            <div class="skill_left">
              <img v-if="item.attrs" :src="require('@/assets/skill/' + item.attrs.heroid + '11.png')"
                :class="{ shadow: item.attrs.heroskill1 == 0 }" width="65px" height="65px" />
              <div class="lv_bg">
                <p class="fs-sm t-center bold t-color8">
                  {{item.attrs.heroskill1.toString().charAt(item.attrs.heroskill1.toString().length - 1)}}
                </p>
              </div>
            </div>
            <div class="m-l-10">
              <p>
                {{ skillname1 }}
                <span class="fs-md">{{ this.$t("card.type") }}: {{ skill1 }}</span>
              </p>
              <p class="fs-md t-color2 m-t-5 lh-3">{{ desc1 }}</p>
              <p class="fs-md t-color8 m-t-5 lh-3 bold" v-if="buff1">【{{ buff1 }}】</p>
              <p class="fs-md t-color8 lh-3" v-if="buffdesc1">{{ buffdesc1 }}</p>
              <p class="fs-md t-color2 m-t-5 lh-3">{{ coll1 }}</p>
              <div class="fs-md m-t-5" v-if="newskill1.preview.length > 0">
                <p class="bold m-b-5">{{ $t("card.upgrade") }}：</p>
                <p v-for="(item, i) in upgrade1" :key="i" class="lh-2">
                  <span v-html="item"></span>
                </p>
              </div>
            </div>
          </div>
          <!-- 技能2 -->
          <div class="d-flex ai-center my-15" v-if="skillname2">
            <div class="skill_left">
              <img v-if="item.attrs" :src="require('@/assets/skill/' + item.attrs.heroid + '21.png')"
                :class="{ shadow: item.attrs.heroskill2 == 0 }" width="65px" height="65px" />
              <div class="lv_bg">
                <p class="fs-sm t-center bold t-color8" v-if="item.attrs.heroskill2 != 0">
                  {{item.attrs.heroskill2.toString().charAt(item.attrs.heroskill1.toString().length - 1)}}
                </p>
                <p class="fs-sm t-center bold t-color8" v-else>
                  {{herolevel2.toString().charAt(item.attrs.heroskill1.toString().length - 1) || 1}}
                </p>
              </div>
            </div>
            <div class="m-l-10">
              <p>
                {{ skillname2 }}
                <span class="fs-md">{{ this.$t("card.type") }}: {{ skill2 }}</span>
              </p>
              <p class="fs-md t-color2 m-t-5 lh-3">
                {{ desc2 }}<span class="bold t-color6" v-html="qidong2"></span>
              </p>
              <p class="fs-md t-color8 m-t-5 lh-3 bold" v-if="buff2">【{{ buff2 }}】</p>
              <p class="fs-md t-color8 lh-3" v-if="buffdesc2">{{ buffdesc2 }}</p>
              <p class="fs-md t-color2 m-t-5 lh-3">{{ coll2 }}</p>
              <div class="fs-md" v-if="newskill2 && newskill2.preview.length > 0">
                <p class="bold m-b-5">{{ $t("card.upgrade") }}：</p>
                <p v-for="(item, i) in upgrade2" :key="i" class="lh-2">
                  <span v-html="item"></span>
                </p>
              </div>
            </div>
          </div>
          <!-- 技能3 -->
          <div class="d-flex ai-center my-15" v-if="skillname3">
            <div class="skill_left">
              <img v-if="item.attrs" :src="require('@/assets/skill/' + item.attrs.heroid + '31.png')"
                :class="{ shadow: item.attrs.heroskill3 == 0 }" width="65px" height="65px" />
              <div class="lv_bg">
                <p class="fs-sm t-center bold t-color8" v-if="item.attrs.heroskill3 != 0">
                  {{item.attrs.heroskill3 .toString().charAt(item.attrs.heroskill1.toString().length - 1)}}
                </p>
                <p class="fs-sm t-center bold t-color8" v-else>
                  {{herolevel3.toString().charAt(item.attrs.heroskill1.toString().length - 1) || 1}}
                </p>
              </div>
            </div>
            <div class="m-l-10">
              <p>
                {{ skillname3 }}
                <span class="fs-md">{{ this.$t("card.type") }}: {{ skill3 }}</span>
              </p>
              <p class="fs-md t-color2 m-t-5 lh-3">
                {{ desc3 }}<span class="bold t-color6" v-html="qidong3"></span>
              </p>
              <p class="fs-md t-color8 m-t-5 lh-3 bold" v-if="buff3">【{{ buff3 }}】</p>
              <p class="fs-md t-color8 lh-3" v-if="buffdesc3">{{ buffdesc3 }}</p>
              <p class="fs-md t-color2 m-t-5 lh-3">{{ coll3 }}</p>
              <div class="fs-md m-t-5" v-if="newskill3 && newskill3.preview.length > 0">
                <p class="bold m-b-5">{{ $t("card.upgrade") }}：</p>
                <p v-for="(item, i) in upgrade3" :key="i" class="lh-2">
                  <span v-html="item"></span>
                </p>
              </div>
            </div>
          </div>
          <!-- 技能4 -->
          <div class="d-flex ai-center my-15" v-if="skillname4">
            <div class="skill_left">
              <img v-if="item.attrs" :src="require('@/assets/skill/' + item.attrs.heroid + '41.png')"
                :class="{ shadow: item.attrs.heroskill4 == 0 }" width="65px" height="65px" />
              <div class="lv_bg">
                <p class="fs-sm t-center bold t-color8" v-if="item.attrs.heroskill4 != 0">
                  {{item.attrs.heroskill4.toString().charAt(item.attrs.heroskill1.toString().length - 1)}}
                </p>
                <p class="fs-sm t-center bold t-color8" v-else>
                  {{herolevel4.toString().charAt(item.attrs.heroskill1.toString().length - 1) || 1}}
                </p>
              </div>
            </div>
            <div class="m-l-10">
              <p>
                {{ skillname4 }}
                <span class="fs-md">{{ this.$t("card.type") }}: {{ skill4 }}</span>
              </p>
              <p class="fs-md t-color2 m-t-5 lh-3">
                {{ desc4 }}<span class="bold t-color6" v-html="qidong4"></span>
              </p>
              <p class="fs-md t-color8 m-t-5 lh-3 bold" v-if="buff4">【{{ buff4 }}】</p>
              <p class="fs-md t-color8 lh-3" v-if="buffdesc4">{{ buffdesc4 }}</p>
              <p class="fs-md t-color2 m-t-5 lh-3">{{ coll4 }}</p>
              <div class="fs-md" v-if="newskill4 && newskill4.preview.length > 0">
                <p class="bold m-b-5">{{ $t("card.upgrade") }}：</p>
                <p v-for="(item, i) in upgrade4" :key="i" class="lh-2">
                  <span v-html="item"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 交易历史 -->
        <div v-if="item.assets_status != 13" class="d-flex ai-center jc-between m-t-40 m-b-15">
          <p class="bold fs-xl">{{ $t("card.history") }}</p>
          <div class="d-flex ai-center">
            <p class="t-color2 fs-md m-r-5">
              <span>{{ $t("card.token") }}:</span>
              {{ this.item.tokenid }}
            </p>
            <img src="@/assets/public/copy.png" width="22px" v-clipboard:copy="this.item.tokenid"
              v-clipboard:success="onCopy" v-clipboard:error="onError" />
          </div>
        </div>
        <div v-if="item.assets_status != 13" class="section">
          <div class="title w-100 t-color2 d-flex ai-center jc-between m-b-20">
            <p></p>
            <p class="price">{{ $t("card.price") }}</p>
            <p>{{ $t("card.to") }}</p>
            <p>{{ $t("card.time") }}</p>
          </div>
          <div v-loading="loading2" element-loading-background="rgba(0, 0, 0, 0.5)" class="history-box">
            <div class="title w-100 d-flex ai-center jc-between m-b-10" v-for="(item, index) in historyList"
              :key="index">
              <p v-if="index"></p>
              <p v-else class="now-box">{{$t("card.from")}}</p>
              <p class="price">{{ item.price | cutZero }} BNB</p>
              <p class="d-flex ai-center">
                <span class="m-r-5">{{ item.seller | plusXing(4, 4) }}</span>
                <img src="@/assets/public/copy.png" width="22px" v-clipboard:copy="item.sell_address"
                  v-clipboard:success="onCopy" v-clipboard:error="onError" />
              </p>
              <p>{{item.tx_time}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 出售卡片 -->
    <sellDom ref="sellDom" @callBack="callBack" :sellObj="sellObj" />
  </div>
</template>

<script>
import { getcardsDetail } from "../api/common/index.api";
import textdata from "../common/dataJaon/text_data.json";
import partner_data1 from "../common/dataJaon/partner_data1.json";
import skilldata from "../common/dataJaon/skill_data.json";
import setData from "../common/set";
import { mapState } from "vuex";
import sellDom from "@/components/sellDom.vue";
import Counts from "@/components/Counts.vue";
export default {
  components: { sellDom, Counts },
  data() {
    return {
      loading: false,
      loading2: false,
      element: "hot",
      item: {},
      hero_name: "",
      arrtibute: {},
      historyList: [],

      heroskill1: {},
      desc1: "",
      buff1: "",
      buffdesc1: "",
      coll1: "",
      skill1: "",
      skillname1: "",
      upgrade1: [],
      newskill1: {},

      heroskill2: {},
      desc2: "",
      buff2: "",
      buffdesc2: "",
      coll2: "",
      skill2: "",
      skillname2: "",
      upgrade2: [],
      newskill2: {},
      qidong2: "",
      herolevel2: "",

      heroskill3: {},
      desc3: "",
      buff3: "",
      buffdesc3: "",
      coll3: "",
      skill3: "",
      skillname3: "",
      upgrade3: [],
      newskill3: {},
      qidong3: "",
      herolevel3: "",

      heroskill4: {},
      desc4: "",
      buff4: "",
      buffdesc4: "",
      coll4: "",
      skill4: "",
      skillname4: "",
      upgrade4: [],
      newskill4: {},
      qidong4: "",
      herolevel4: "",
      sellObj: {},
    };
  },
  created() {
    this.getDetail();
  },
  computed: {
    ...mapState({
      infos: (state) => state.infos,
    }),
  },
  methods: {
    goChain() {
      window.open('/xieyi.html')
    },
    //种族 1-水，2-火，3-风，4-光，5-暗
    campStatus(val) {
      switch (val) {
        case 1:
          return this.$t("market.campList.element2");
          break;
        case 2:
          return this.$t("market.campList.element3");
          break;
        case 3:
          return this.$t("market.campList.element4");
          break;
        case 4:
          return this.$t("market.campList.element5");
          break;
        case 5:
          return this.$t("market.campList.element6");
          break;
        default:
          break;
      }
    },
    //职业 2-法师,3-战士,4-坦克,5-辅助
    prostatus(val) {
      switch (val) {
        case 2:
          return this.$t("market.professionList.element3");
          break;
        case 3:
          return this.$t("market.professionList.element2");
          break;
        case 4:
          return this.$t("market.professionList.element4");
          break;
        case 5:
          return this.$t("market.professionList.element5");
          break;
        default:
          break;
      }
    },
    onCopy() {
      this.$message.success(this.$t("copy.ok"));
    },
    onError() {
      this.$message.error(this.$t("copy.no"));
    },
    gethistoryList(tokenid) {
      this.loading2 = true;
      this.$axios
        .get(this.$api.historyk + "/" + tokenid, {})
        .then((res) => {
          this.historyList = res.data;
          this.loading2 = false;
        }).catch(() => {
          this.loading2 = false;
        });
    },
    getDetail() {
      this.loading = true;
      getcardsDetail(
        this.$route.query.id,
        {},
        { Serverid: this.$store.state.serverid }
      ).then((res) => {
        this.gethistoryList(res.data.tokenid);
        let name = partner_data1.data_partner_base[res.data.attrs.heroid].name;
        this.hero_name =
          this._i18n.locale == "en"
            ? textdata.data_text[name].s_En
            : this._i18n.locale == "tw" ? textdata.data_text[name].s_Tw : textdata.data_text[name].s_Yn;
        let arrtibute = new setData().getData(res.data.attrs);
        this.arrtibute = arrtibute;
        let heroskill1 = skilldata.skill_data[res.data.attrs.heroskill1] || {}; //技能1
        let heroskill2 =
          skilldata.skill_data[res.data.attrs.heroid + "21"] || {}; //技能2
        let heroskill3 =
          skilldata.skill_data[res.data.attrs.heroid + "31"] || {}; //技能3
        let heroskill4 =
          skilldata.skill_data[res.data.attrs.heroid + "41"] || {}; //技能4
        this.heroskill1 = heroskill1;
        this.heroskill2 = heroskill2;
        this.heroskill3 = heroskill3;
        this.heroskill4 = heroskill4;
        if (heroskill1)
          this.desc1 =
            this._i18n.locale == "en"
              ? textdata.data_text[heroskill1.desc1].s_En
              : this._i18n.locale == "tw" ? textdata.data_text[heroskill1.desc1].s_Tw :
                textdata.data_text[heroskill1.desc1].s_Yn; //技能1描述
        if (JSON.stringify(heroskill2) != "{}")
          this.desc2 =
            this._i18n.locale == "en"
              ? textdata.data_text[heroskill2.desc1].s_En
              : this._i18n.locale == "tw" ? textdata.data_text[heroskill2.desc1].s_Tw :
                textdata.data_text[heroskill2.desc1].s_Yn; //技能2描述
        if (JSON.stringify(heroskill3) != "{}")
          this.desc3 =
            this._i18n.locale == "en"
              ? textdata.data_text[heroskill3.desc1].s_En
              : this._i18n.locale == "tw" ? textdata.data_text[heroskill3.desc1].s_Tw :
                textdata.data_text[heroskill3.desc1].s_Yn; //技能3描述
        if (JSON.stringify(heroskill4) != "{}")
          this.desc4 =
            this._i18n.locale == "en"
              ? textdata.data_text[heroskill4.desc1].s_En
              : this._i18n.locale == "tw" ? textdata.data_text[heroskill4.desc1].s_Tw :
                textdata.data_text[heroskill4.desc1].s_Yn; //技能4描述

        if (heroskill1 && heroskill1.type != 2) {
          if (this._i18n.locale == "en") {
            this.coll1 =
              "Cooldown " +
              heroskill1.cd +
              " turn, release " +
              heroskill1.release +
              " turn"; //技能1冷却和释放
          } else if (this._i18n.locale == "tw") {
            this.coll1 =
              "冷却" +
              heroskill1.cd +
              "回合,第" +
              heroskill1.release +
              "回合释放"; //技能1冷却和释放
          } else {
            this.coll1 =
              "Tenang untuk " +
              heroskill1.cd +
              " pusingan,Ronde" +
              heroskill1.release +
              "lepaskan"; //技能1冷却和释放
          }
        } else {
          this.coll1 = "";
        }
        if (JSON.stringify(heroskill2) != "{}" && heroskill2.type != 2) {
          if (this._i18n.locale == "en") {
            this.coll2 =
              "Cooldown " +
              heroskill2.cd +
              " turn, release " +
              heroskill2.release +
              " turn"; //技能2冷却和释放
          } else if (this._i18n.locale == "tw") {
            this.coll2 =
              "冷却" +
              heroskill2.cd +
              "回合,第" +
              heroskill2.release +
              "回合释放"; //技能2冷却和释放
          } else {
            this.coll2 =
              "Tenang untuk " +
              heroskill2.cd +
              " pusingan,Ronde" +
              heroskill2.release +
              "lepaskan"; //技能2冷却和释放
          }
        } else {
          this.coll2 = "";
        }
        if (JSON.stringify(heroskill3) != "{}" && heroskill3.type != 2) {
          if (this._i18n.locale == "en") {
            this.coll3 =
              "Cooldown " +
              heroskill3.cd +
              " turn, release " +
              heroskill3.release +
              " turn"; //技能3冷却和释放
          } else if (this._i18n.locale == "tw") {
            this.coll3 =
              "冷却" +
              heroskill3.cd +
              "回合,第" +
              heroskill3.release +
              "回合释放"; //技能3冷却和释放
          } else {
            this.coll3 =
              "Tenang untuk " +
              heroskill3.cd +
              " pusingan,Ronde" +
              heroskill3.release +
              "lepaskan"; //技能2冷却和释放
          }
        } else {
          this.coll3 = "";
        }
        if (JSON.stringify(heroskill4) != "{}" && heroskill4.type != 2) {
          if (this._i18n.locale == "en") {
            this.coll4 =
              "Cooldown " +
              heroskill4.cd +
              " turn, release " +
              heroskill4.release +
              " turn"; //技能4冷却和释放
          } else if (this._i18n.locale == "tw") {
            this.coll4 =
              "冷却" +
              heroskill4.cd +
              "回合,第" +
              heroskill4.release +
              "回合释放"; //技能4冷却和释放
          } else {
            this.coll4 =
              "Tenang untuk " +
              heroskill4.cd +
              " pusingan,Ronde" +
              heroskill4.release +
              "lepaskan"; //技能2冷却和释放
          }
        } else {
          this.coll4 = "";
        }
        let skill1;
        let skill2;
        let skill3;
        let skill4;
        skill1 = skilldata.skill_data[res.data.attrs.heroskill1];
        if (res.data.attrs.heroskill2 == 0) {
          let ski2 = skilldata.skill_data[res.data.attrs.heroid + "21"];
          let detail = [];
          if (ski2 && ski2.preview && ski2.preview.length > 0) {
            ski2.preview.forEach((ele, i) => {
              // console.log(skilldata.skill_data[ele % 10000000].lv);
              let suo2 =
                this._i18n.locale == "en"
                  ? textdata.data_text[
                    skilldata.skill_data[(ele % 10000000) - 1].skill_desc
                  ].s_En
                  : this._i18n.locale == "tw" ? textdata.data_text[
                    skilldata.skill_data[(ele % 10000000) - 1].skill_desc
                  ].s_Tw : textdata.data_text[
                    skilldata.skill_data[(ele % 10000000) - 1].skill_desc
                  ].s_Yn;
              var e = />([1-9])?.*<\//.exec(suo2);
              if (e.length == 2) {
                detail.push({
                  lv: skilldata.skill_data[ele % 10000000].lv,
                  sou: e[1],
                });
              }
              if (res.data.attrs.herostar >= Number(detail[0].sou)) {
                skill2 =
                  skilldata.skill_data[
                  res.data.attrs.heroid + "2" + detail[0].lv.toString()
                  ];
              } else {
                skill2 =
                  skilldata.skill_data[
                  res.data.attrs.heroid + "2" + (detail[0].lv - 1).toString()
                  ];
              }
            });
            // console.log(skill2);
          }
          if (ski2) {
            let qidong =
              this._i18n.locale == "en"
                ? textdata.data_text[ski2.open_desc].s_En
                : this._i18n.locale == "tw" ? textdata.data_text[ski2.open_desc].s_Tw :
                  textdata.data_text[ski2.open_desc].s_Yn;
            this.qidong2 = "（" + qidong + "）";
          }
          if (detail.length > 0) {
            if (res.data.attrs.herostar >= Number(detail[0].sou)) {
              this.herolevel2 =
                res.data.attrs.heroid + "2" + detail[0].lv.toString();
            } else {
              this.herolevel2 =
                res.data.attrs.heroid + "2" + (detail[0].lv - 1).toString();
            }
          }
        } else {
          skill2 = skilldata.skill_data[res.data.attrs.heroskill2];
        }
        if (res.data.attrs.heroskill3 == 0) {
          let ski3 = skilldata.skill_data[res.data.attrs.heroid + "31"];
          let detail = [];
          if (ski3 && ski3.preview && ski3.preview.length > 0) {
            ski3.preview.forEach((ele, i) => {
              // console.log(skilldata.skill_data[ele % 10000000].lv);
              let suo3 =
                this._i18n.locale == "en"
                  ? textdata.data_text[
                    skilldata.skill_data[(ele % 10000000) - 1].skill_desc
                  ].s_En
                  : this._i18n.locale == "tw" ? textdata.data_text[
                    skilldata.skill_data[(ele % 10000000) - 1].skill_desc
                  ].s_Tw : textdata.data_text[
                    skilldata.skill_data[(ele % 10000000) - 1].skill_desc
                  ].s_Yn;
              var e = />([1-9])?.*<\//.exec(suo3);
              if (e.length == 2) {
                detail.push({
                  lv: skilldata.skill_data[ele % 10000000].lv,
                  sou: e[1],
                });
              }
              if (res.data.attrs.herostar >= Number(detail[0].sou)) {
                skill3 =
                  skilldata.skill_data[
                  res.data.attrs.heroid + "3" + detail[0].lv.toString()
                  ];
              } else {
                skill3 =
                  skilldata.skill_data[
                  res.data.attrs.heroid + "3" + (detail[0].lv - 1).toString()
                  ];
              }
              // console.log(detail);
            });
          }
          if (ski3) {
            let qidong =
              this._i18n.locale == "en"
                ? textdata.data_text[ski3.open_desc].s_En
                : this._i18n.locale == "tw" ? textdata.data_text[ski3.open_desc].s_Tw :
                  textdata.data_text[ski3.open_desc].s_Yn;
            this.qidong3 = "（" + qidong + "）";
          }
          if (detail.length > 0) {
            if (res.data.attrs.herostar >= Number(detail[0].sou)) {
              this.herolevel3 =
                res.data.attrs.heroid + "3" + detail[0].lv.toString();
            } else {
              this.herolevel3 =
                res.data.attrs.heroid + "3" + (detail[0].lv - 1).toString();
            }
          }
          // console.log(detail);
        } else {
          skill3 = skilldata.skill_data[res.data.attrs.heroskill3];
        }
        if (res.data.attrs.heroskill4 == 0) {
          let ski4 = skilldata.skill_data[res.data.attrs.heroid + "41"];
          let detail = [];
          if (ski4 && ski4.preview && ski4.preview.length > 0) {
            ski4.preview.forEach((ele, i) => {
              // console.log(skilldata.skill_data[ele % 10000000].lv);
              let suo4 =
                this._i18n.locale == "en"
                  ? textdata.data_text[
                    skilldata.skill_data[(ele % 10000000) - 1].skill_desc
                  ].s_En
                  : this._i18n.locale == "tw" ? textdata.data_text[
                    skilldata.skill_data[(ele % 10000000) - 1].skill_desc
                  ].s_Tw : textdata.data_text[
                    skilldata.skill_data[(ele % 10000000) - 1].skill_desc
                  ].s_Yn;
              var e = />([1-9])?.*<\//.exec(suo4);
              if (e.length == 2) {
                detail.push({
                  lv: skilldata.skill_data[ele % 10000000].lv,
                  sou: e[1],
                });
              }
              if (res.data.attrs.herostar >= Number(detail[0].sou)) {
                skill4 =
                  skilldata.skill_data[
                  res.data.attrs.heroid + "4" + detail[0].lv.toString()
                  ];
              } else {
                skill4 =
                  skilldata.skill_data[
                  res.data.attrs.heroid + "4" + (detail[0].lv - 1).toString()
                  ];
              }
              // console.log(detail);
            });
          }
          if (ski4) {
            let qidong =
              this._i18n.locale == "en"
                ? textdata.data_text[ski4.open_desc].s_En
                : this._i18n.locale == "tw" ? textdata.data_text[ski4.open_desc].s_Tw :
                  textdata.data_text[ski4.open_desc].s_Yn;
            this.qidong4 = "（" + qidong + "）";
          }
          if (detail.length > 0) {
            if (res.data.attrs.herostar >= Number(detail[0].sou)) {
              this.herolevel4 =
                res.data.attrs.heroid + "4" + detail[0].lv.toString();
            } else {
              this.herolevel4 =
                res.data.attrs.heroid + "4" + (detail[0].lv - 1).toString();
            }
          }
          // console.log(skill4);
        } else {
          skill4 = skilldata.skill_data[res.data.attrs.heroskill4];
        }
        this.newskill1 = skill1;
        this.newskill2 = skill2;
        this.newskill3 = skill3;
        this.newskill4 = skill4;
        // 技能升级
        let allHeroSkill = [];
        allHeroSkill.push(skill1, skill2 || [], skill3 || [], skill4 || []);
        // console.log(allHeroSkill);
        allHeroSkill.forEach((ele, index) => {
          if (ele.preview && ele.preview.length > 0) {
            ele.preview.forEach((ele2, index) => {
              if (ele2.toString().slice(-2, -1) == 1) {
                // console.log(ele2 % 10000000);
                // console.log(skilldata.skill_data[ele2 % 10000000].skill_desc);
                let suo =
                  textdata.data_text[
                  skilldata.skill_data[(ele2 % 10000000) - 1].skill_desc
                  ];
                if (this._i18n.locale == "en") {
                  return this.upgrade1.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_En +
                    "（" +
                    suo.s_En +
                    "）"
                  );
                } else if (this._i18n.locale == "tw") {
                  return this.upgrade1.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_Tw +
                    "（" +
                    suo.s_Tw +
                    "）"
                  );
                } else {
                  return this.upgrade1.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_Yn +
                    "（" +
                    suo.s_Yn +
                    "）"
                  );
                }
              }
              if (ele2.toString().slice(-2, -1) == 2) {
                let suo =
                  textdata.data_text[
                  skilldata.skill_data[(ele2 % 10000000) - 1].skill_desc
                  ];
                if (this._i18n.locale == "en") {
                  return this.upgrade2.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_En +
                    "（" +
                    suo.s_En +
                    "）"
                  );
                } else if (this._i18n.locale == "tw") {
                  return this.upgrade2.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_Tw +
                    "（" +
                    suo.s_Tw +
                    "）"
                  );
                } else {
                  return this.upgrade2.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_Yn +
                    "（" +
                    suo.s_Yn +
                    "）"
                  );
                }
              }
              if (ele2.toString().slice(-2, -1) == 3) {
                let suo =
                  textdata.data_text[
                  skilldata.skill_data[(ele2 % 10000000) - 1].skill_desc
                  ];
                if (this._i18n.locale == "en") {
                  return this.upgrade3.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_En +
                    "（" +
                    suo.s_En +
                    "）"
                  );
                } else if (this._i18n.locale == "tw") {
                  return this.upgrade3.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_Tw +
                    "（" +
                    suo.s_Tw +
                    "）"
                  );
                } else {
                  return this.upgrade3.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_Yn +
                    "（" +
                    suo.s_Yn +
                    "）"
                  );
                }
              }
              if (ele2.toString().slice(-2, -1) == 4) {
                let suo =
                  textdata.data_text[
                  skilldata.skill_data[(ele2 % 10000000) - 1].skill_desc
                  ];
                if (this._i18n.locale == "en") {
                  return this.upgrade4.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_En +
                    "（" +
                    suo.s_En +
                    "）"
                  );
                } else if (this._i18n.locale == "tw") {
                  return this.upgrade4.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_Tw +
                    "（" +
                    suo.s_Tw +
                    "）"
                  );
                } else {
                  return this.upgrade4.push(
                    "LV." +
                    skilldata.skill_data[ele2 % 10000000].lv +
                    " " +
                    textdata.data_text[ele2].s_Yn +
                    "（" +
                    suo.s_Yn +
                    "）"
                  );
                }
              }
            });
          }
        });
        if (heroskill1)
          this.skillname1 =
            this._i18n.locale == "en"
              ? textdata.data_text[heroskill1.name].s_En
              : this._i18n.locale == "tw" ? textdata.data_text[heroskill1.name].s_Tw :
                textdata.data_text[heroskill1.name].s_Yn; //技能1名称
        if (JSON.stringify(heroskill2) != "{}")
          this.skillname2 =
            this._i18n.locale == "en"
              ? textdata.data_text[heroskill2.name].s_En
              : this._i18n.locale == "tw" ? textdata.data_text[heroskill2.name].s_Tw :
                textdata.data_text[heroskill2.name].s_Yn; //技能2名称
        if (JSON.stringify(heroskill3) != "{}")
          this.skillname3 =
            this._i18n.locale == "en"
              ? textdata.data_text[heroskill3.name].s_En
              : this._i18n.locale == "tw" ? textdata.data_text[heroskill3.name].s_Tw :
                textdata.data_text[heroskill3.name].s_Yn; //技能3名称
        if (JSON.stringify(heroskill4) != "{}")
          this.skillname4 =
            this._i18n.locale == "en"
              ? textdata.data_text[heroskill4.name].s_En
              : this._i18n.locale == "tw" ? textdata.data_text[heroskill4.name].s_Tw :
                textdata.data_text[heroskill4.name].s_Yn; //技能4名称

        if (heroskill1) {
          if (heroskill1.buffs.length > 0) {
            let bufftotal1 = skilldata.skill_buff_data[heroskill1.buffs[0]];
            if (bufftotal1 && bufftotal1.name != 0)
              this.buff1 =
                this._i18n.locale == "en"
                  ? textdata.data_text[bufftotal1.name].s_En
                  : this._i18n.locale == "tw" ? textdata.data_text[bufftotal1.name].s_Tw :
                    textdata.data_text[bufftotal1.name].s_Yn; //技能1buff
          }
          if (heroskill1.buff_desc.length > 0) {
            let buffdesc1 = skilldata.skill_buff_data[heroskill1.buff_desc[0]];
            console.log(buffdesc1);
            if (buffdesc1 && buffdesc1.value != 0) {
              this.buffdesc1 =
                this._i18n.locale == "en"
                  ? textdata.data_text[buffdesc1.value].s_En
                  : this._i18n.locale == "tw" ? textdata.data_text[buffdesc1.value].s_Tw :
                    textdata.data_text[buffdesc1.value].s_Yn; //技能1buff描述
            }
          }
        }
        if (JSON.stringify(heroskill2) != "{}") {
          let bufftotal2;
          let buffdesc2;
          if (res.data.attrs.heroskill2 == 0) {
            bufftotal2 = skilldata.skill_buff_data[heroskill2.buffs[0]];
            buffdesc2 = skilldata.skill_buff_data[heroskill2.buff_desc[0]];
          } else {
            bufftotal2 = skilldata.skill_buff_data[skill2.buffs[0]];
            buffdesc2 = skilldata.skill_buff_data[skill2.buff_desc[0]];
          }
          // let bufftotal2 = skilldata.skill_buff_data[skill2.buffs[0]];
          if (bufftotal2 && bufftotal2.name != 0)
            this.buff2 =
              this._i18n.locale == "en"
                ? textdata.data_text[bufftotal2.name].s_En
                : this._i18n.locale == "tw" ? textdata.data_text[bufftotal2.name].s_Tw :
                  textdata.data_text[bufftotal2.name].s_Yn; //技能2buff
          if (buffdesc2 && buffdesc2.value != 0)
            this.buffdesc2 =
              this._i18n.locale == "en"
                ? textdata.data_text[buffdesc2.value].s_En
                : this._i18n.locale == "tw" ? textdata.data_text[buffdesc2.value].s_Tw :
                  textdata.data_text[buffdesc2.value].s_Yn; //技能2buff描述
        }
        if (JSON.stringify(heroskill3) != "{}") {
          let bufftotal3;
          let buffdesc3;
          if (res.data.attrs.heroskill3 == 0) {
            bufftotal3 = skilldata.skill_buff_data[heroskill3.buffs[0]];
            buffdesc3 = skilldata.skill_buff_data[heroskill3.buff_desc[0]];
          } else {
            bufftotal3 = skilldata.skill_buff_data[skill3.buffs[0]];
            buffdesc3 = skilldata.skill_buff_data[skill3.buff_desc[0]];
          }
          if (bufftotal3 && bufftotal3.name != 0)
            this.buff3 =
              this._i18n.locale == "en"
                ? textdata.data_text[bufftotal3.name].s_En
                : this._i18n.locale == "tw" ? textdata.data_text[bufftotal3.name].s_Tw :
                  textdata.data_text[bufftotal3.name].s_Yn; //技能3buff
          if (buffdesc3 && buffdesc3.value != 0)
            this.buffdesc3 =
              this._i18n.locale == "en"
                ? textdata.data_text[buffdesc3.value].s_En
                : this._i18n.locale == "tw" ? textdata.data_text[buffdesc3.value].s_Tw :
                  textdata.data_text[buffdesc3.value].s_Yn; //技能3buff描述
        }
        if (JSON.stringify(heroskill4) != "{}") {
          let bufftotal4;
          let buffdesc4;
          if (res.data.attrs.heroskill3 == 0) {
            bufftotal4 = skilldata.skill_buff_data[heroskill4.buffs[0]];
            buffdesc4 = skilldata.skill_buff_data[heroskill4.buff_desc[0]];
          } else {
            bufftotal4 = skilldata.skill_buff_data[skill4.buffs[0]];
            buffdesc4 = skilldata.skill_buff_data[skill4.buff_desc[0]];
          }
          if (bufftotal4 && bufftotal4.name != 0)
            this.buff4 =
              this._i18n.locale == "en"
                ? textdata.data_text[bufftotal4.name].s_En
                : this._i18n.locale == "tw" ? textdata.data_text[bufftotal4.name].s_Tw :
                  textdata.data_text[bufftotal4.name].s_Yn; //技能4buff
          if (buffdesc4 && buffdesc4.value != 0)
            this.buffdesc4 =
              this._i18n.locale == "en"
                ? textdata.data_text[buffdesc4.value].s_En
                : this._i18n.locale == "tw" ? textdata.data_text[buffdesc4.value].s_Tw :
                  textdata.data_text[buffdesc4.value].s_Yn; //技能4buff描述
        }
        if (heroskill1)
          heroskill1.type == 1
            ? (this.skill1 = this.$t("card.zhudong"))
            : (this.skill1 = this.$t("card.beidong"));
        if (JSON.stringify(heroskill2) != "{}")
          heroskill2.type == 1
            ? (this.skill2 = this.$t("card.zhudong"))
            : (this.skill2 = this.$t("card.beidong"));
        if (JSON.stringify(heroskill3) != "{}")
          heroskill3.type == 1
            ? (this.skill3 = this.$t("card.zhudong"))
            : (this.skill3 = this.$t("card.beidong"));
        if (JSON.stringify(heroskill4) != "{}")
          heroskill4.type == 1
            ? (this.skill4 = this.$t("card.zhudong"))
            : (this.skill4 = this.$t("card.beidong"));

        this.item = res.data;
        this.item.hero_name = this.hero_name;
        this.loading = false;
      });
    },
    toSell(item) {
      this.sellObj = item;
      this.sellObj.type = "card"
      this.$refs.sellDom.sellShow = true;
    },
    callBack({ type, val }) {
      switch (type) {
        case "reAuctionDom":
        case "auctionDom":
          this.$refs.sellDom.sellShow = false;
          if (val != "success") return this.$message(val);
          this.$msgbox.alert(
            "#" + this.sellObj.tokenid + this.$t("account.auctionsuc"),
            this.$t("messageBox.tip2"),
            {
              confirmButtonText: this.$t("messageBox.confirm"),
              center: true,
              callback: (action) => {
                if (action === "confirm") {
                  this.getDetail();
                }
              },
            }
          );
          break;
        case "buyDom":
          this.$refs.sellDom.sellShow = false;
          if (val != "success") return this.$message(val);
          this.$msgbox.alert(
            "#" + this.sellObj.tokenid + this.$t("account.reAuctionsuc"),
            this.$t("messageBox.tip2"),
            {
              confirmButtonText: this.$t("messageBox.confirm"),
              center: true,
              callback: (action) => {
                if (action === "confirm") {
                  this.getDetail();
                }
              },
            }
          );
          break;
      }
    },
  },
};
</script>

<style lang="scss" scope>
.opration {
  width: 120px;
  height: 38px;
  background: #317F6E;
  border-radius: 4px;
  line-height: 38px;
}
.opration2 {
  background: #f49d14;
}
.hasactive {
  border: 1px solid #999999;
  background: transparent;
}
.section {
  width: 100%;
  background: rgba(41, 43, 57, 0.5);
  border: 1px solid #4c5158;
  border-radius: 4px;
  padding: 20px 40px;
  .colbox {
    .col {
      width: 25%;
    }
  }
  .border_b {
    border-bottom: 1px solid #464853;
  }
  .border_r {
    border-right: 1px solid #464853;
  }
  .left,
  .right {
    width: 50%;
    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .title {
    p {
      width: 25%;
    }
    .price {
      width: 15%;
    }
  }
  .skill_left {
    position: relative;
    .shadow {
      -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
      filter: grayscale(100%);
    }
    .lv_bg {
      width: 23px;
      height: 23px;
      line-height: 23px;
      background: url("../assets/card/yuan.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.main {
  align-items: flex-start;
  .main_left {
    width: 460px;
    position: relative;
    h1 {
      color: #f3e9ca;
    }
    .forsale {
      // width: 200px;
      // height: 100px;
      // background: url("../assets/card/card_sell.png") no-repeat;
      // background-size: 100% 100%;
      // position: absolute;
      // top: 30%;
      .notallow {
        border: 1px solid;
        border-radius: 4px;
      }
    }
  }
}
.box-111 {
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  img {
    height: 30px;
    margin-right: 5px;
  }
  img,
  span {
    vertical-align: middle;
  }
}
.history-box {
  > div:nth-of-type(1) {
    // background: rgba(0,0,0,0.3);
  }
  .now-box {
    height: 32px;
    line-height: 32px;
    background-image: url("../assets/account/price.png");
    background-size: 100% 100%;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .opration {
    margin-top: 10px;
  }
  .el-main {
    padding: 0;
  }
  .title_back {
    margin: 0 10px;
    flex-wrap: wrap;
    p {
      margin-bottom: 10px;
    }
  }
  .main {
    flex-wrap: wrap;
    .main_right {
      margin-left: 0;
      .section {
        padding: 10px;
        .title {
          p {
            overflow-x: scroll;
          }
        }
      }
      .info {
        margin-top: 20px;
      }
    }
  }
}
</style>